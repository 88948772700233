<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> Create New Currency</CButton
        >
        <br />
        <br />
        <br />
        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.active)">
                {{ item.active == true ? "Active" : "Inactive" }}
              </CBadge>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  deleteId = item.id;
                  deletedItem = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CModal
          v-model="deletedItem.id"
          title="Are you sure to delete selected currency?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="6">
              <CInput label="Name" v-model="deletedItem.name" disabled />
            </CCol>
            <CCol col="6">
              <CInput
                label="Currency Code"
                v-model="deletedItem.currencyCode"
                disabled
              />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "Currencies",
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      items: [],
      deletedItem: [],
      warningModal: false,
      fields: ["status", "name", "currencyCode", "currencyPrefix", "currencySuffix", "EDIT", "DELETE"],

      // Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.deleteId);
      }
    },
    currencyLink(id) {
      return `currencies/${id.toString()}`;
    },
    editLink(id) {
      return `currencies/create/${id.toString()}`;
    },

    edit(id) {
      const editLink = this.editLink(id);
      this.$router.push({ path: editLink });
    },
    delete(id) {
      let self = this;
      axios
        .delete(this.$apiAdress + "/v1/Currency/" + id)

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted currency.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: "currencies/create/" + this.emptyGuid });
    },

    getList() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Currency")
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
